<template>
  <div class="pageContol">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">考试管理</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">学员考场查询</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start">
          <div class="searchbox" style="margin-bottom: 10px;">
            <div title="考务名称" class="searchboxItem ci-full">
              <span class="itemLabel">考务名称:</span>
              <el-input v-model="searchForm.activityName" clearable size="small" placeholder="请输入考务名称"></el-input>
            </div>
            <div title="培训工种" class="searchboxItem ci-full">
              <span class="itemLabel">培训工种:</span>
              <el-select v-model="searchForm.occupationId" filterable remote clearable size="small"
                :remote-method="getOccupation" placeholder="请至少输入两个字搜索">
                <el-option v-for="item in occupation" :key="item.occupationId" :label="item.occupationName"
                  :value="item.occupationId">
                </el-option>
              </el-select>
            </div>
            <!-- <div title="考试科目" class="searchboxItem ci-full">
              <span class="itemLabel">考试科目:</span>
              <el-select v-model="searchForm.subjectCode" filterable clearable size="small" placeholder="请选择考试科目">
                <el-option v-for="item in subjectCodeList" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </div> -->
            <div title="考场" class="searchboxItem ci-full">
              <span class="itemLabel">考场:</span>
              <el-input v-model="searchForm.classroomName" clearable size="small" placeholder="请输入考场"></el-input>
            </div>
            <div title="考试时间" class="searchboxItem ci-full">
              <span class="itemLabel">考试时间:</span>
              <el-date-picker clearable size="small" v-model="searchForm.times" type="daterange" range-separator="至"
                start-placeholder="开始日期" end-placeholder="结束日期" format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"></el-date-picker>
            </div>
            <el-button class="bgc-bv" style="margin: 0 10px" round @click="getData()">查询</el-button>
            <el-button class="bgc-bv" style="margin: 0 10px" round @click="exportSeeExaminationRoom">导出</el-button>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table ref="multipleTable" :data="tableData" :height="tableHeight" size="small" tooltip-effect="dark"
              style="width: 100%" :header-cell-style="tableHeader" stripe>
              <el-table-column label="序号" align="center" type="index" :index="indexMethod" width="100px" />
              <el-table-column label="姓名" align="left" prop="userName" show-overflow-tooltip width="100px" />
              <el-table-column label="身份证" align="left" prop="idcard" show-overflow-tooltip width="150px" />
              <el-table-column label="手机号" align="left" prop="mobile" show-overflow-tooltip width="120px" />
              <el-table-column label="考务名称" align="left" prop="activityName" show-overflow-tooltip width="200px" />
              <el-table-column label="培训工种" align="left" prop="occupationName" show-overflow-tooltip width="200px" />
              <el-table-column label="考试信息" align="left" prop="subjectCode" show-overflow-tooltip width="300px">
                <template slot-scope="scope">
                  <template v-if="scope.row.examList.length > 0">
                    <div v-for="item in scope.row.examList" :key="item.activityExamId">
                      {{
                        $setDictionary("EA_EXAM_SUBJECT_CODE", item.subjectCode)
                      }}
                      : {{ item.startTime }} - {{ item.endTime }}
                    </div>
                  </template>
                  <template v-else> 无 </template>
                </template>
              </el-table-column>
              <el-table-column label="考场" align="left" prop="classroomName" show-overflow-tooltip width="120px" />
              <el-table-column label="座位号" align="left" prop="seatNumber" show-overflow-tooltip width="80px" />
              <el-table-column label="是否补考" align="left" prop="purchased" show-overflow-tooltip width="90px">
                <template slot-scope="scope">
                  {{ scope.row.purchased ? '是' : '否' }}
                </template>
              </el-table-column>
              <el-table-column label="备注" align="left" prop="remarks" show-overflow-tooltip min-width="150" />
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
      </div>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { mapGetters } from "vuex";
export default {
  name: "studentExaminationRoomQueryList",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      // 检索数据
      searchForm: {
        activityName: "", // 考务名称
        occupationId: "", // 培训工种
        // subjectCode: "", // 考试科目
        classroomName: "", // 考场
        times: [], // 考试时间
      },
      // 培训工种 - 下拉数据
      occupation: [],
      // 考试科目 - 下拉数据
      // subjectCodeList: [],
    };
  },
  created() {
    // this.dataDictionary();
  },
  computed: {
    ...mapGetters({
      downloadItems: "getDownloadItems",
    }),
  },
  methods: {
    // 获取 - 培训工种
    getOccupation(occupationName) {
      if (occupationName.trim().length >= 2) {
        this.$post("/biz/examination_affairs/activity/selectCtOccupationList", { occupationName }, 3000, true, 6)
          .then((ret) => {
            this.occupation = ret.data
          })
          .catch((err) => {
            return;
          });
      } else {
        this.occupation = [];
      }
    },
    // 获取 - 数据字典
    dataDictionary() {
      // 考试科目
      const arr1 = this.$setDictionary("EA_EXAM_SUBJECT_CODE", "list");
      for (const key in arr1) {
        this.subjectCodeList.push({
          value: key,
          label: arr1[key],
        });
      }
    },
    // 获取列表数据
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        ...this.searchForm
      };
      if (this.searchForm.times) {
        params.startTimeStart = this.searchForm.times[0];
        params.startTimeEnd = this.searchForm.times[1];
      }
      this.doFetch(
        {
          url: "/biz/examination_affairs/activity/userRoomPageList",
          params,
          pageNum,
        }, true, 6
      );
    },
    // 导出
    exportSeeExaminationRoom() {
      const params = {
        ...this.searchForm
      };
      if (this.searchForm.times) {
        params.startTimeStart = this.searchForm.times[0];
        params.startTimeEnd = this.searchForm.times[1];
      }
      this.$post(
        "/biz/examination_affairs/activity/userRoomPageList/export",
        params, 3000, true, 6
      )
        .then((res) => {
          const arr = [...res.data];
          for (let item of arr) {
            if (!this.downloadItems.includes(item.taskId)) {
              this.$store.dispatch("pushDownloadItems", item.taskId);
            } else {
              this.$message.warning(
                "[" + item.fileName + "]已经申请下载,请耐心等待"
              );
            }
          }
        })
        .catch(() => {
          return;
        });
    },
  },
};
</script>
<style lang="less" scoped></style>